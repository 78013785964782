// libs
import Cookies from "js-cookie";

/**
 * Function for retrieving the a3s-settings from cookies.
 * Once it is retrieved, decode and parse it.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getA3SSettings = () => {
    // reterive the settings from cookies, once it is reterieve decode and parse it.
    const a3sSettings = JSON.parse(atob(Cookies.get("a3s-settings")));

    // return the a3s-settings.
    return a3sSettings;
};
