// libs
import { useDispatch } from "react-redux";
import { useCallback, useRef } from "react";

// stores
import { showAlertMessage, hideAlertMessage } from "../../store/PageAlertReducer";

// constants
import { ERROR_MESSAGE, ErrorEnum } from "../../constants/Error";

// types
import { IAlert } from "../../defs/Alert";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useAlertMessageHandler() {
    const dispatch = useDispatch();
    const timerRef = useRef(null);

    const showErrorAlertMessage = useCallback((errors, customErrorMessage?: IAlert): void => {
        /**
         * if ErrorType exist and equal to ErrorEnum in that case we will show the Enum specific
         * error message otherwise we will show generic Server Error message.
         */
        const errorMessage: IAlert =
            errors.length && errors[0].errorType && ERROR_MESSAGE[errors[0].errorType]
                ? ERROR_MESSAGE[errors[0].errorType]
                : ERROR_MESSAGE[ErrorEnum.SERVER_ERROR];
        dispatch(showAlertMessage({ alertMessage: customErrorMessage ?? errorMessage, enableRefreshCTA: false }));

        // set the timer to hide the alert after autoTimeout seconds
        timerRef.current = setTimeout(() => {
            hideErrorAlertMessage();
            timerRef.current = null; // reset the ref
        }, errorMessage.autoTimeout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hideErrorAlertMessage = useCallback((): void => {
        dispatch(hideAlertMessage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showSuccessAlertMessage = useCallback((alertMessage: IAlert): void => {
        dispatch(showAlertMessage({ alertMessage, enableRefreshCTA: false }));

        // set the timer to hide the alert after passed autoTimeout seconds
        timerRef.current = setTimeout(() => {
            hideErrorAlertMessage();
            timerRef.current = null; // reset the ref
        }, alertMessage.autoTimeout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hideSuccessAlertMessage = useCallback((): void => {
        dispatch(hideAlertMessage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { showErrorAlertMessage, hideErrorAlertMessage, showSuccessAlertMessage, hideSuccessAlertMessage };
}

export default useAlertMessageHandler;
