/**
 * Adding disable Horizontal swipe function to disable the
 * horizontal scrolling.
 */
export const disableHorizontalSwipe = (): void => {
    let isScrolling;
    window.addEventListener(
        "wheel",
        (event) => {
            if (event.deltaX !== 0) {
                event.preventDefault();
                cancelAnimationFrame(isScrolling);
                isScrolling = requestAnimationFrame(() => {
                    console.log("Preventing swipe navigation");
                });
            }
        },
        { passive: false },
    );
};
